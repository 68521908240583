/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        // function addBlacklistClass() {
        //   $( 'a' ).each( function() {
        //     if ( this.href.indexOf('/wp-admin/') !== -1 ||
        //     this.href.indexOf('/wp-login.php') !== -1 ) {
        //       $( this ).addClass( 'wp-link' );
        //     }
        //   });
        // }
        //
        // addBlacklistClass();

        // var settings = {
        //   anchors: 'a',
        //   blacklist: '.wp-link',
        //   debug: true,
        //   onStart: {
        //     duration: 280,
        //     render: function ( $container ) {
        //       $container.addClass( 'slide-out' );
        //     }
        //   },
        //   onAfter: function( $container ) {
        //
        //     addBlacklistClass();
        //
        //     var $hash = $( window.location.hash );
        //
        //     if ( $hash.length !== 0 ) {
        //
        //       var offsetTop = $hash.offset().top;
        //
        //       $( 'body, html' ).animate( {
        //         scrollTop: ( offsetTop - 60 ),
        //       }, {
        //         duration: 280
        //       } );
        //     }
        //
        //     $container.removeClass( 'slide-out' );
        //   }
        // };
        //
        // $( '#content' ).smoothState( settings );

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('#menu-main-navigation').attr('data-height', $('#menu-main-navigation').height() + 30);

        if ($(window).width() <= 768) {
          var theMobileHeight = $('#menu-main-navigation').height();
          $('#menu-main-navigation').css('top', -theMobileHeight + 5);
        }

        function mobileImageHolder() {
          if ($(window).width() <= 544) {

            $('.image-holder').unbind();

            $('.image-holder').hide();

            $('.push-images').click(function(){

              $('#content .image-holder img').load(function(){
                $('#content .image-holder').each(function(){
                  var imageHeight = $(this).find('img').height();
                  $(this).css('height', imageHeight + 15).hide();
                });
              });
              $('#content .image-holder').fadeToggle();
            });


          }
          console.log('Set Image Holder Height.');
        }
        mobileImageHolder();

        // This activates the animated Burger Menu
        function activateHamburger() {
          var $hamburger = $('.hamburger');
          $hamburger.on('click', function(e) {
            $hamburger.toggleClass('is-active');

            // Do something else, like open/close menu
            $('body').toggleClass('burger-open');
            theMobileHeight1 = $('#menu-main-navigation').attr('data-height');

            if ($("#menu-main-navigation").hasClass('is-active')) {
              $("#menu-main-navigation").animate({
                top: -theMobileHeight + 5
              }, 500).removeClass('is-active');
            } else {
              $("#menu-main-navigation").animate({
                top: 0
              }, 500).addClass('is-active');
            }
          });

        }
        activateHamburger();

        function makeTopNavSticky() {
          $(window).scroll(function() {

            var navToTop = $('.nav-primary').offset().top;

            if (window.scrollY >= (navToTop + 62)) {

              var topNavHeight = $('.menu-main-navigation-container').height();
              $('.menu-main-navigation-container').addClass('scrolled').attr('data-height', $('.menu-main-navigation-container').height());
            } else {
              $('.menu-main-navigation-container').removeClass('scrolled').attr('data-height', $('.menu-main-navigation-container').height() + 30);
            }
          });
          console.log('Made Nav sticky;');
        }
        if ($(window).width() > 991) {
          makeTopNavSticky();

        }

        // This handles mobile _NAv mechanixx
        function doTheMobileNav() {
          // On Plus Click Toggle Submenu in Nav
          function toggleMobileSubmenu() {

            $('#menu-main-navigation > .menu-item-has-children > a > i.fa').on('click', function(e) {
              e.preventDefault();
              $(this).toggleClass('clicked');
              $(this).parent().parent().find('.sub-menu').first().toggleClass('clicked');
              $(this).text(function(i, v) {
                return v === '+' ? '–' : '+';
              });
              var theMobileHeight = $('#menu-main-navigation').height();
              $('#menu-main-navigation').attr('data-height', theMobileHeight);
            });
          }

          if ($(window).width() <= 768) {


            $('#menu-main-navigation > .menu-item-has-children > a').each(function() {
              $(this).append('<i class="fa">+</i>');
            });

            //toggleMobileSubmenu();

            var theMobileHeight = $('#menu-main-navigation').height();
            $('#menu-main-navigation').attr('data-height', theMobileHeight);
            theMobileHeight = $('#menu-main-navigation').attr('data-height');

            $('#menu-main-navigation').css('top', -theMobileHeight + 5);

            $('.burger').toggle(function() {
              $("#menu-main-navigation").animate({
                top: 0
              }, 500, function() {
                // Animation complete.
                $('#burger-check').prop('checked', true);
              });
            }, function() {
              theMobileHeight = $('#menu-main-navigation').attr('data-height');
              $("#menu-main-navigation").animate({
                top: -theMobileHeight + 5
              }, 500, function() {
                $('#burger-check').prop('checked', false);
              });
            });

          }
        }
        //doTheMobileNav();

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.slick-carousel').slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
